import React, { useState, useContext, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  REMOVE_PRODUCT,
  SET_CART_PRODUCT,
} from "../reducers/CartReducer";
import { EventContext } from "../context/EventContext";
import { CartContext } from "../context/CartContext";
import { getAvailability, getSizeScale } from "../firestore";
import { asyncFilter, getBitmap, getNzForSizeInSizeScale, getPriceString, getEncodedProductStyle, getEncodedProductColor, capitalizeFirstLetter } from "../helpers";

const CheckoutProduct = ({ id, productID, size, quantity, embroidery }) => {
  const [removing, setRemoving] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [sizeScale, setSizeScale] = useState([]);
  const [selectedSize, setSelectedSize] = useState(size);
  const [selectedQuantity, setSelectedQuantity] = useState(quantity);
  const [embroiderySelected, setEmbroiderySelected] = useState(!!embroidery);
  const [initials, setInitials] = useState();
  const [selectedTape, setSelectedTape] = useState(embroidery ? embroidery.tape: null);
  const [selectedPosition, setSelectedPosition] = useState(embroidery ? embroidery.position : null);
  const [availableQuantity, setAvailableQuantity] = useState();
  const { eventID, products, productList, customUnits, lastLogoDate, monogramTapes } = useContext(EventContext);
  const { cartState, cartDispatch } = useContext(CartContext);

  const thisCartProduct = Object.values(cartState.products).find(product => {
    return product.size === size
      && product.quantity === quantity
      && product.product === productID
      && product.embroidery === embroidery
  });

  // Set the monogram initials from the cart
  useEffect(() => {
    const initialsFromCart = thisCartProduct?.embroidery?.tape?.monogram;
    if (!initialsFromCart) return;
    setInitials(initialsFromCart);
  }, [thisCartProduct]);

  const product = products[productID];
  const productStyle = getEncodedProductStyle(product);
  const productColor = getEncodedProductColor(product);

  const oneTapeOption = product?.embroidery?.tapes?.length === 1;
  const onePositionOption = product?.embroidery?.positions?.length === 1;
  const oneEmbroideryOption = oneTapeOption && onePositionOption;

  const getAvailableQuantity = useCallback(async () => {
    if (!product || !selectedSize) return;
    if (sizeScale.length !== 0) {
      const nz = getNzForSizeInSizeScale(selectedSize, sizeScale);
      const qty = await getAvailability(product, nz);
      setAvailableQuantity(qty);
    }
  }, [product, selectedSize, setAvailableQuantity, sizeScale]);

  // Remove this product from the cart
  const remove = useCallback(async () => {
    setRemoving(true);
    try {
      cartDispatch({
        type: REMOVE_PRODUCT,
        cartProduct: {
          id,
          size,
          quantity,
          embroidery,
          price: product.price,
          product,
        },
        productList,
      });
    } catch (error) {
      console.log(`Error removing product: ${error}`);
      setRemoving(false);
    }
  }, [setRemoving, product, cartDispatch, id, size, quantity, productList, embroidery]);

  // Set the cart product if anything changes
  useEffect(() => {
    if (!id
      || !selectedSize
      || !selectedQuantity
      || !selectedTape
      || !selectedPosition) return;
    const embroidery = embroiderySelected
      ? {
        tape: selectedTape,
        position: selectedPosition,
      }
      : null;
    cartDispatch({
      type: SET_CART_PRODUCT,
      id,
      cartProduct: {
        size: selectedSize,
        quantity: selectedQuantity,
        embroidery,
      },
    });
  }, [id, selectedSize, selectedQuantity, selectedTape, selectedPosition, initials, cartDispatch, embroiderySelected]);

  const changeTape = useCallback(async (tapeName) => {
    const isMonogram = monogramTapes.includes(tapeName);
    var result;
    // Set the initials if they haven't been set yet
    while (isMonogram
      && !initials
      && (!result || result === "")) {
      result = prompt("Enter your initials for the monogram.");
    }
    const tape = isMonogram
      ? {
        monogram: initials ? initials : result.toUpperCase(),
        name: tapeName,
      }
      : {
        name: tapeName,
      };
    setSelectedTape(tape);
  }, [monogramTapes, initials, setSelectedTape]);

  const toggleEmbroidery = useCallback(async () => {
    setEmbroiderySelected(selected => !selected);
    // Remove the embroidery
    if (embroiderySelected) {
      return;
    }
    // Set the embroidery to the first option
    const firstTape = product.embroidery.tapes[0];
    const firstPosition = product.embroidery.positions[0];
    changeTape(firstTape);
    setSelectedPosition(firstPosition);
  },[product, embroiderySelected, setSelectedPosition, changeTape]);

  const changePosition = useCallback(async (event) => {
    if (!embroiderySelected) return;
    const positionCode = event.target.value;
    const allPositions = product.embroidery?.positions;
    const newPosition = allPositions.find(p => p.code === positionCode);
    setSelectedPosition(newPosition);
  }, [product, embroiderySelected, setSelectedPosition]);

  // Set the size scale for this product
  useEffect(() => {
    if (!product || !selectedSize) return;
    getSizeScale(product.xID).then((sizes) => {
      // Only show available sizes
      asyncFilter(sizes, async (size) => {
        const nz = getNzForSizeInSizeScale(size, sizes);
        const availability = await getAvailability(product, nz);
        return availability > 0;
      }).then(available => {
        setSizes(available);
        setSizeScale(sizes);
      });
    });
  }, [product, selectedSize]);

  // Set the quantity for the selected size
  useEffect(() => {
    getAvailableQuantity();
  }, [getAvailableQuantity]);
  // Dispatch to cart when selected quantity changes

  const changeQuantity = useCallback(
    async (event) => {
      const newQuantity = event.target.value;
      setSelectedQuantity(parseInt(newQuantity));
    },
    [setSelectedQuantity]
  );

  // Display size scale options for this product
  const renderSizeOptions = useCallback(() => {
    return sizes.map((s, i) => {
      return (
        <option key={i} value={s}>
          {s}
        </option>
      );
    });
  }, [sizes]);

  // Display quantity options for the size selection
  const renderQuantityOptions = useCallback(() => {
    if (availableQuantity === 0 && !removing && !!product) {
      return;
    }
    // There aren't enough left, warn the user
    if (selectedQuantity > availableQuantity) {
      alert(`Sorry, we don't have enough of ${product.styleNameLong} left in size ${selectedSize}. Your quantity has been reduced to our current availability.`);
      setSelectedQuantity(availableQuantity);
    }
    return Array.from({ length: availableQuantity }, (_, i) => i + 1).map(
      (q) => {
        return (
          <option value={q} key={q}>
            {q}
          </option>
        );
      }
    );
  }, [selectedQuantity, selectedSize, availableQuantity, removing, product]);

  // Display tape options for this product
  const renderTapeOptions = useCallback(() => {
    return product?.embroidery?.tapes?.map((name, i) => {
      const isMonogram = monogramTapes.includes(name);
      return (
        <option key={i} value={name}>
          {isMonogram ? `MONOGRAM ${initials ? initials : ""}` : name}
        </option>
      );
    });
  }, [monogramTapes, initials, product?.embroidery?.tapes]);

  // Display embroidery position options for this product
  const renderPositionOptions = useCallback(() => {
    return product.embroidery.positions.map((p, i) => {
      return (
        <option key={i} value={p.code}>
          {p.name}
        </option>
      );
    });
  }, [product?.embroidery?.positions]);

  const renderSizeSelector = useCallback(() => {
    return (
        <div className="sizeSelector">
          <label>Size</label>
          <select
            value={selectedSize}
            onChange={(e) => setSelectedSize(e.target.value)}
            className="sizeOptions"
          >
            {renderSizeOptions()}
          </select>
        </div>
    );
  }, [selectedSize, renderSizeOptions]);

  const renderQuantitySelector = useCallback(() => {
    return (
      <div className="quantitySelector">
        <label>Quantity</label>
        <select
          value={selectedQuantity}
          onChange={(e) => changeQuantity(e)}
          className="quantityOptions"
        >
          {renderQuantityOptions()}
        </select>
      </div>
    );
  }, [selectedQuantity, changeQuantity, renderQuantityOptions]);

  const renderOneEmbroideryOption = useCallback(() => {
    const firstTape = product?.embroidery?.tapes[0];
    const firstPosition = product?.embroidery?.positions[0];
    if (!firstTape || !firstPosition) return;
    const tapeImage = getBitmap(firstTape);
    const name = capitalizeFirstLetter(firstPosition.name);
    return (
      <div className="configLogo">
        <div className="eventchoice nonEssentialWorker">
          <div className="nonEssentialWorker">
            <label className="container" id="embroidery">
              <input
                checked={embroiderySelected ? "checked" : ""}
                onChange={(e) => {toggleEmbroidery()}}
                type="checkbox"
                name="embroideryEnabled" />
              <span className="checkmark"></span>
              <img className="tapeImage" alt="" src={tapeImage} />
              <span className="logoPlacement">
                <h3>Add {initials ? `"${initials}"` : "Logo"} on {name}</h3>
              </span>
            </label>
          </div>
        </div>
      </div>
    );
  }, [product, embroiderySelected, toggleEmbroidery, initials]);

  const renderPositionSelector = useCallback(() => {
    return (
      <div className="positionSelector">
        <label>Logo Location</label>
        <select
          value={selectedPosition.code}
          onChange={(e) => changePosition(e)}
          className="positionOptions"
        >
          {renderPositionOptions()}
        </select>
      </div>
    );
  }, [selectedPosition, changePosition, renderPositionOptions]);

  const renderTapeSelector = useCallback(() => {
    return (
      <div className="tapeSelector">
        <label>Logo</label>
        <select
          value={selectedTape.name}
          onChange={(e) => changeTape(e.target.value)}
          className="tapeOptions"
        >
          {renderTapeOptions()}
        </select>
      </div>
    );
  }, [selectedTape, changeTape, renderTapeOptions]);

  const renderRemoveButton = useCallback(() => {
    return (
      <div className="remover">
        <button onClick={() => remove()} className="removeItem">
          {removing ? "Removing From Cart..." : "Remove from cart"}
        </button>
      </div>
    );
  }, [remove, removing]);

  const renderEmbroideryToggler = useCallback(() => {
    return (
      <div className="logoToggler">
        <button onClick={() => toggleEmbroidery()} className="toggleLogo">
          {removing ? "Removing..." : `${embroiderySelected ? "Remove" : "Add"} logo`}
        </button>
      </div>
    );
  }, [toggleEmbroidery, removing, embroiderySelected]);

  if (!product) return null;
  const style = product.styleNameLong ? product.styleNameLong : product.styleCode;
  const color = product.colorNameLong ? product.colorNameLong : product.colorCode;
  const showEmbroidery =
    product.embroidery
    && lastLogoDate?.toDate() > new Date()
  const showMultipleEmbroidery = showEmbroidery && !oneEmbroideryOption;
  const showSingleEmbroidery = showEmbroidery && oneEmbroideryOption;

  return (
    <div className="checkoutProduct greyBorder">
      <Link
        to={`/${eventID}/store/product/${productStyle}/${productColor}`}
        className="productImage productImage"
        style={{ backgroundImage: `url(${getBitmap(product.image)})` }}
      ></Link>
      <div className="checkoutProductInfo">
        <div className="topLine">
          <span className="productName">{style}</span>
          <span className="productPrice">
            {getPriceString(product.price, customUnits)}</span>
        </div>

        {showSingleEmbroidery && renderOneEmbroideryOption()}
        <span className="color">{color}</span>
        {renderSizeSelector()}
        {renderQuantitySelector()}
        {renderRemoveButton()}

        {showMultipleEmbroidery && embroiderySelected && renderPositionSelector()}
        {showMultipleEmbroidery && embroiderySelected && renderTapeSelector() }
        {showMultipleEmbroidery && renderEmbroideryToggler() }
      </div>
    </div>
  );
};

export default CheckoutProduct;
