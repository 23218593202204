import React, { useCallback } from "react";
import { getBitmap, capitalizeFirstLetter } from "../helpers";

const Embroidery = ({
  embroidery,
  monogram, setMonogram,
  initials, setInitials,
  selectedTape, setSelectedTape,
  selectedPosition, setSelectedPosition,
  embroiderySelected, setEmbroiderySelected
}) => {
  const positions = embroidery.positions;
  const tapes = embroidery.tapes;

  const oneTapeOption = tapes?.length === 1;
  const onePositionOption = positions?.length === 1;
  const oneEmbroideryOption = oneTapeOption && onePositionOption;

  const firstTape = embroidery?.tapes[0];
  const firstPosition = embroidery?.positions[0];

  const toggleEmbroidery = useCallback(() => {
    setEmbroiderySelected(selected => {
      // Reset the embroidery options if we are untoggling
      if (selected) {
        setSelectedTape(null)
        setSelectedPosition(null)
      }
      return !selected
    });
    // If there's only one embroidery option, set it with a toggle
    if (oneTapeOption) setSelectedTape(tape => tape ? null : firstTape);
    if (onePositionOption) setSelectedPosition(position => position ? null : firstPosition);
  }, [firstTape, firstPosition, setSelectedPosition, setSelectedTape, onePositionOption, oneTapeOption, setEmbroiderySelected]);

  // A radio button option for a tape image
  const tapeOption = useCallback((tape) => {
    const tapeImage = getBitmap(tape);
    return (
      <label
        className="embroideryOption"
        key={tape}
        onClick={() => setSelectedTape(tape)}
      >
        <input type="radio" value={tape} name="tape" />
        <div className="tapeImage">
          <img alt="tape" src={tapeImage} />
        </div>
        <input type="radio" value={tape} name="tape" />
      </label>
    );
  }, [setSelectedTape]);

  // A radio button option for a tape position
  const positionOption = useCallback((position) => {
    const name = capitalizeFirstLetter(position.name);
    return (
      <label
        className="embroideryOption"
        key={JSON.stringify(position)}
        onClick={() => setSelectedPosition(position)}
      >
        <input type="radio" value={position} name="position" />
        <h3 className="positionCodeText">{name}</h3>
        <input type="radio" value={position} name="position" />
      </label>
    );
  }, [setSelectedPosition])

  // The position and tape options
  const embroideryOptions = useCallback(() => {
    const tapeOptions = tapes.map(t => tapeOption(t));
    const positionOptions = positions.map(p => positionOption(p));
    return (
      <div>
        <div className="radioContainer">
          {!onePositionOption && positionOptions}
        </div>
        <div className="radioContainer">
          {tapeOptions}
        </div>
      </div>
    );
  }, [tapes, positions, tapeOption, onePositionOption, positionOption]);

  // Monogram tapes require the user to enter their initials
  const renderMonogramInput = useCallback(() => {
    return (
      <div>
        <input
          style={{"textTransform": "uppercase"}}
          name="monogram"
          type="text"
          className="validated-field"
          placeholder="Initials"
          onChange={(e) => setInitials(e.target.value.toUpperCase())}
          required
          autoFocus
        />
      </div>
    );
  }, [setInitials])

  // Use a checkbox to toggle one embroidery option
  const singleEmbroideryOption = useCallback(() => {
    const tapeImage = getBitmap(firstTape);
    const name = capitalizeFirstLetter(firstPosition.name);
    return (
      <label className="container" id="embroidery">
        <input
          checked={embroiderySelected ? "checked" : ""}
          onChange={(e) => {toggleEmbroidery()}}
          type="checkbox"
          name="embroideryEnabled" />
        <span className="checkmark"></span>
        <img alt="" src={tapeImage} />
        <span className="logoPlacement">
          <h3>Add {monogram ? "Monogram" : "Logo"} on {name}</h3>
        </span>
      </label>
    );
  }, [embroiderySelected, toggleEmbroidery, firstPosition, firstTape, monogram])

  // Use a checkbox to toggle multiple embroidery options
  const multipleEmbroideryOptions = useCallback(() => {
    const name = capitalizeFirstLetter(firstPosition.name);
    return (
      <div>
        <label className="container" id="multipleEmbroidery">
          <input
            checked={embroiderySelected ? "checked" : ""}
            onChange={(e) => {toggleEmbroidery()}}
            type="checkbox"
            name="embroideryEnabled" />
          <span className="checkmark"></span>
          <span className="logoPlacement">
            <h3>Add Logo{onePositionOption && ` On ${name}`}</h3>
          </span>
        </label>
        {embroiderySelected && embroideryOptions()}
      </div>
    );
  }, [embroiderySelected, toggleEmbroidery, embroideryOptions, firstPosition, onePositionOption])

  if (!embroidery
    || !tapes
    || !positions
    || tapes.length === 0
    || positions.length === 0) { return; }

  return (
    <div>
      {oneEmbroideryOption
      ? singleEmbroideryOption()
      : multipleEmbroideryOptions()}
      {monogram && selectedTape && renderMonogramInput()}
    </div>
  );
};

export default Embroidery;
