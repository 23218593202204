import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { CartContext } from "../context/CartContext";
import { EventContext } from "../context/EventContext";
import { AuthContext } from "../context/AuthContext";
import { useForm, Controller } from "react-hook-form";

import countriesObject from "../countryCodes";
import statesObject from "../stateCodes";
import Header from "./Header";
import Cart from "./Cart";
import CartSummary from "./CartSummary";

const Checkout = () => {

  useEffect(() => {
    document.body.className = "checkout dropShip";
    return () => {
      document.body.className = "";
    };
  }, []);

  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const { user, setUser } = useContext(AuthContext);
  const { customer, eventID } = useContext(EventContext);
  const { cartRef, addressRef, submitOrder, sortingPath, dropShip } = useContext(CartContext);
  const { register, trigger, setValue, getValues, handleSubmit, formState: { errors }, control } = useForm();

  // Saves the user's address
  const save = useCallback(() => {
    // Only save the address if it's the user's drop ship address
    if (dropShip) {
      // Convert address to uppercase to avoid problems with Jesta
      const uppercaseAddress = Object.fromEntries(
        Object.entries(getValues()).map(([k, v]) =>
          typeof v === "string"
          ? [k, v.toUpperCase()]
          : [k, v])
      );
      // Set the user's address
      setUser(user => {
        return {
          ...user,
          address: uppercaseAddress
        };
      });
    }
  }, [dropShip, getValues, setUser]);

  // Set the user's address if they have it saved, else use customer address
  useEffect(() => {
    if (!user || !customer || !customer.name) return null;

    const {
      firstName,
      lastName,
      street,
      street2,
      city,
      state,
      zip,
      country,
    } = user.address || {};

    setValue("firstName", !!dropShip ? firstName : customer.name);
    setValue("lastName", !!dropShip ? lastName : "");
    setValue("street", !!dropShip ? street : customer.street);
    setValue("street2", !!dropShip ? street2 : customer.street2);
    setValue("city", !!dropShip ? city : customer.city);
    setValue("state", !!dropShip ? state : customer.state);
    setValue("zip", !!dropShip ? zip : customer.zip);
    setValue("country", !!dropShip ? country : customer.countryCode);
  }, [dropShip, user, customer, setValue]);

  const populateCountries = useCallback(async () => {
    try {
      setStateOptions(statesObject.map(state => {
        return {value: state.code, label: state.name}
      }));
      setCountryOptions(countriesObject.map(country => {
        return {value: country.code, label: country.name}
      }));
    } catch (err) {
      console.log(`Error fetching countries: ${err}`);
    }
  }, []);

  // Populate the list of countries
  useEffect(() => {
    populateCountries();
  }, [populateCountries]);

  const renderForm = useCallback(() => {
    return (
      <form
        onSubmit={handleSubmit(submitOrder)}
        ref={addressRef}
        className="shippingForm">
        <div className="shippingFormHeader">
          <h2>Shipping Address</h2>
        </div>
        <div className="shippingFormBody">
          {dropShip ? null :
            <div className="shippingFormBodyRow">
              <label>Name</label>
              <input style={{"textTransform": "uppercase"}}
                pattern="[a-zA-Z]*"
                placeholder={`${user.firstName} ${user.lastName ? user.lastName : ""}`}
                disabled={true}
                type="text" />
            </div>
          }
          <div className="shippingFormBodyRow">
            <label>{dropShip ? "First Name" : "Club Name"}</label>
            <input style={{"textTransform": "uppercase"}} pattern="[a-zA-Z]*" {...register("firstName", { required: dropShip })}
              className={errors.firstName ? "invalid-field" : ""}
              onBlur={() => save()}
              placeholder="First Name"
              disabled={!dropShip}
              type="text" />
          </div>
          {dropShip ?
            <div className="shippingFormBodyRow">
              <label>Last Name</label>
              <input style={{"textTransform": "uppercase"}} pattern="[a-zA-Z]*" {...register("lastName", { required: false })}
                className={errors.lastName ? "invalid-field" : ""}
                onBlur={() => save()}
                placeholder="Last Name"
                disabled={!dropShip}
                type="text" />
            </div>
            : null}
          <div className="shippingFormBodyRow">
            <label>Street</label>
            <input style={{"textTransform": "uppercase"}} pattern="[a-zA-Z]*" {...register("street", { required: dropShip })}
              className={errors.street ? "invalid-field" : ""}
              onBlur={() => save()}
              placeholder="Address Line 1"
              disabled={!dropShip}
              type="text" />
          </div>
          <div className="shippingFormBodyRow">
            <input style={{"textTransform": "uppercase"}} pattern="[a-zA-Z]*" {...register("street2", { required: false })}
              className={errors.street2 ? "invalid-field" : ""}
              onBlur={() => save()}
              placeholder="Address Line 2"
              disabled={!dropShip}
              type="text" />
          </div>
          <div className="shippingFormBodyRow">
            <label>City</label>
            <input style={{"textTransform": "uppercase"}} pattern="[a-zA-Z]*" {...register("city", { required: dropShip })}
              className={errors.city ? "invalid-field" : ""}
              onBlur={() => save()}
              placeholder="City"
              disabled={!dropShip}
              type="text" />
          </div>
          <div className="shippingFormBodyRow">
            <label>State</label>
            <Controller
              name="state"
              control={control}
              render={({ onChange, value, name, ref }) =>
              <Select className="react-select"
                classNamePrefix={errors.state ? "invalid-field" : ""}
                {...register("state", { required: dropShip })}
                inputRef={ref}
                isDisabled={!dropShip}
                options={stateOptions}
                value={stateOptions.find(c => c.value === getValues()["state"])}
                onChange={state => {
                  setValue("state", state.value);
                  save(state);
                }}
              />}
            />
          </div>
          <div className="shippingFormBodyRow">
            <label>Zip Code</label>
            <input pattern="[0-9]*" {...register("zip", { required: dropShip })}
              className={errors.zip ? "invalid-field" : ""}
              onBlur={() => save()}
              placeholder="Zip"
              disabled={!dropShip}
              type="text" />
          </div>
          <div className="shippingFormBodyRow">
            <label>Country</label>
            <Controller
              name="country"
              control={control}
              render={({ onChange, value, name, ref }) =>
              <Select className="react-select"
                classNamePrefix={errors.country ? "invalid-field" : ""}
                {...register("country", { required: dropShip })}
                inputRef={ref}
                isDisabled={!dropShip}
                options={countryOptions}
                value={countryOptions.find(c => c.value === getValues()["country"])}
                onChange={country => {
                  setValue("country", country.value);
                  save(country);
                }}
              />}
            />
          </div>
        </div>
      </form>
    );
  }, [errors, dropShip, addressRef, submitOrder, control, stateOptions, countryOptions, getValues, handleSubmit, register, save, setValue, user.firstName, user.lastName]);

  // In case the customer is empty for some reason, show this message
   const renderShippingMessage = () => {
    return (
      <div
        ref={addressRef}
      >
        <h2>
          Shipping
        </h2>
        <em>
          The order will be shipped to the club. Don't worry, we know your name!
        </em>
      </div>
    );
  }

  return (
    <>
      <Header/>
      <div className="productPane">
        <div ref={cartRef} className="checkoutGrid">
          <div>
            <Link to={!!sortingPath ? sortingPath : `/${eventID}/store`} className="backLink iconArrow">
              <span className="iconArrow" />
              Back to Shopping
            </Link>
            <Cart/>
            {!dropShip && (!customer || !customer.name)
            ? renderShippingMessage()
            : renderForm()}
          </div>
          <div className="summary">
            <div className="summaryWrapper">
              <CartSummary
                trigger={trigger}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
