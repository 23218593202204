import React, { useContext, useEffect, useCallback } from "react";
import Product from "./Product";
import Header from "./Header";
import MainNav from "./MainNav";
import { EventContext } from "../context/EventContext";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getGenderFromTitle } from "../helpers";

const filterProductsByCategory = (products, category, subCategory) => {
  if (!category) return products;
  const gender = getGenderFromTitle(category);
  const categoryFilter = products.filter((p) => p.genderCode === gender);
  const subCategoryFilter = categoryFilter.filter((p) => p.category === subCategory);
  return subCategory ? subCategoryFilter : categoryFilter;
};

const StoreCategory = () => {
  const history = useHistory();
  const { setSortingPath, products } = useContext(EventContext);
  const match = useRouteMatch("/:eventID/store/:category/:subCategory?");
  const category = match ? match.params.category : null;
  const subCategory = match ? match.params.subCategory : null;

  useEffect(() => {
    document.body.className = "storecategory";
    return () => {
      document.body.className = "";
    };
  }, []);

  // Use context to store the last sorting options for returning to shopping
  useEffect(() => {
    setSortingPath(history.location.pathname);
  }, [subCategory, setSortingPath, history]);

  const renderProducts = useCallback(() => {
    const productObjects = Object.values(products);
    const filtered = filterProductsByCategory(productObjects, category, subCategory)

    return filtered.map((product, i) => {
      return (
        <div key={i} className={`nonEssentialWorker gridRow${i + 1}`}>
          <Product product={product} />
        </div>
      );
    });
  }, [products, category, subCategory]);


  return (
    <React.Fragment>
      <Header />
      <MainNav />
      <div className="view ng-scope">
        <div className="productPane">
          <div className="breadcrumbs">
            {category
              ? subCategory
                ? category + " / " + subCategory
                : category
              : "All Products"}
          </div>
          <div className="productGrid">
            {products
              ? renderProducts()
              : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default StoreCategory;
